import { customServices } from './custom-pages.config';

const UI_BASE_URL = 'https://www.schoolstools.com';

export const product = UI_BASE_URL + '/product';

export const news = UI_BASE_URL + '/news';

export const service = UI_BASE_URL + '/service';

export const customService =
	customServices.map(c => ({
		type: c.type,
		previewUrl: UI_BASE_URL + c.previewPath
	}));