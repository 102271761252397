export const defaultLang = 'en';
export const multiLangUI = false;
export const multiLangInputs = true;
export const langs = [
	{ key: 'en', name: 'English' },
	{ key: 'ar', name: 'العربية' },
	// { key: 'es', name: 'Español' },
	// { key: 'fr', name: 'France' },
	// { key: 'fa', name: 'فارسی' },
	// { key: 'de', name: 'Deutsch' },
];

export const login = {
	en: {
		singular: 'login',
		plural: 'login'
	},
	ar: ''
}

export const preview = {
	en: {
		singular: 'preview',
		plural: 'preview'
	},
	ar: ''
}

export const dashboard = {
	en: {
		singular: 'dashboard',
		plural: 'dashboard'
	},
	ar: ''
}

export const sets = {
	en: {
		singular: 'set',
		plural: 'sets'
	},
	ar: ''
}

export const categories = {
	en: {
		singular: 'category',
		plural: 'categories'
	},
	ar: ''
}

export const category = {
	en: {
		singular: 'category',
		plural: 'categories'
	},
	ar: ''
}
export const filters = {
	en: {
		singular: 'filter',
		plural: 'filters'
	},
	ar: ''
}
export const keywords = {
	en: {
		singular: 'keyword',
		plural: 'keywords'
	},
	ar: ''
}

export const products = {
	en: {
		singular: 'product',
		plural: 'products'
	},
	ar: ''
}

export const product = {
	en: {
		singular: 'product',
		plural: 'products'
	},
	ar: ''
}
export const news = {
	en: {
		singular: 'news',
		plural: 'news'
	},
	ar: ''
}

export const newsIndex = {
	en: {
		singular: 'news',
		plural: 'news'
	},
	ar: ''
}

export const services = {
	en: {
		singular: 'service',
		plural: 'services'
	},
	ar: ''
}

export const service = {
	en: {
		singular: 'service',
		plural: 'services'
	},
	ar: ''
}

export const orders = {
	en: {
		singular: 'order',
		plural: 'orders'
	},
	ar: ''
}

export const order = {
	en: {
		singular: 'order',
		plural: 'orders'
	},
	ar: ''
}

export const plans = {
	en: {
		singular: 'plan',
		plural: 'plans'
	},
	ar: ''
}

export const plan = {
	en: {
		singular: 'plan',
		plural: 'plans'
	},
	ar: ''
}

export const albums = {
	en: {
		singular: 'album',
		plural: 'albums'
	},
	ar: ''
}

export const videos = {
	en: {
		singular: 'video',
		plural: 'videos'
	},
	ar: ''
}

export const sliders = {
	en: {
		singular: 'slider',
		plural: 'sliders'
	},
	ar: ''
}

export const users = {
	en: {
		singular: 'user',
		plural: 'users'
	},
	ar: ''
}

export const userInfo = {
	en: {
		singular: 'user info',
		plural: 'users info'
	},
	ar: ''
}

export const branches = {
	en: {
		singular: 'branch',
		plural: 'branches'
	},
	ar: ''
}

export const branch = {
	en: {
		singular: 'branch',
		plural: 'branches'
	},
	ar: ''
}

export const subscriptions = {
	en: {
		singular: 'subscription',
		plural: 'subscriptions'
	},
	ar: ''
}

export const aboutUs = {
	en: {
		singular: 'about us',
		plural: 'about us',
	},
	ar: ''
}

export const companyValues = {
	en: {
		singular: 'Company value',
		plural: 'Company values'
	},
	ar: ''
}

export const companyValue = {
	en: {
		singular: 'Company value',
		plural: 'Company values'
	},
	ar: ''
}

export const companyHistories = {
	en: {
		singular: 'Company history',
		plural: 'Company histories'
	},
	ar: ''
}

export const companyHistory = {
	en: {
		singular: 'Company history',
		plural: 'Company histories'
	},
	ar: ''
}

export const fileManager = {
	en: {
		singular: 'File manager',
		plural: 'File manager',
	},
	ar: ''
}

export const SEO = {
	en: {
		singular: 'SEO',
		plural: 'SEO',
	},
	ar: ''
}