<template>
	<div>
		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="branches"
			:ids="deleteItemId"
			@on-delete="fetchBranches"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('Branches.the-branch')}}
		</bee-delete-dialog>

		<!-- restore dialog -->
		<bee-restore-dialog
			v-model="restoreDialog"
			moduleType="branches"
			:ids="restoreItemId"
			@on-restore="fetchBranches"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase orange--text text-darken-1">{{$t('restore')}}</span> 
			{{$t('Branches.the-branch')}}
		</bee-restore-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-3">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-btn
							color="darkGreen white--text"
							class="rounded-small"
							@click="dialog = true"
							:to="{ name: 'branch' }"
							:disabled="useOneBranch && Boolean(branches.length)"
						>
							<v-icon>mdi-plus</v-icon>
                            {{$t('Branches.add-new-branch')}}
						</v-btn>
					</v-col>

					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : filteredBranches"
			:loading="loading"
			:items-per-page="15"
			class="elevation-3 rounded-medium"
		>
			<!-- image -->
			<template v-slot:item.image="{ item }">
				<v-img
					:src="item.primaryImage.url"
					:height="45"
					:width="45"
					lazy-src="../../assets/placeholder-image.png"
					transition="scale-transition"
					class="rounded-medium mx-auto"
				/>
			</template>

			<!-- country code -->
			<template v-slot:item.countryCode="{ item }">
				{{item.countryCode | isAvailable | ellipsis}}
			</template>

			<!-- money code -->
			<template v-slot:item.moneyCode="{ item }">
				{{item.moneyCode | isAvailable | ellipsis}}
			</template>

			<!-- open hours -->
			<template v-slot:item.openHours="{ item }">
				{{item.openHours | isAvailable | ellipsis}}
			</template>

			<!-- is main -->
			<template v-slot:item.isMain="{ item }">
				<v-icon v-if="item.isMain">mdi-check-circle-outline</v-icon>
				<span v-else>-</span>
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<div class="d-flex justify-center align-center">
					<template v-if="!item.deletedAt">
						<v-btn
							color="blue darken-4"
							dark
							small
							class="ms-1 px-1 flex-shrink-1"
							min-width="20px"
							:to="{ name: 'branch', params: { slug: item.slug, id: item.id } }"
						><v-icon>mdi-pencil</v-icon></v-btn>
						
						<v-btn
							color="red darken-1"
							dark
							small
							class="ms-1 px-1"
							min-width="20px"
							@click="deleteItemId = item.id; deleteDialog = true"
						>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</template>
					<v-btn
						v-else
						color="orange darken-1"
						dark
						small
						class="px-1"
						min-width="20px"
						@click="restoreItemId = item.id; restoreDialog = true"
					>
						<v-icon>mdi-restore</v-icon>
					</v-btn>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { branchInfo, useOneBranch, useUploadBranchImage } from '@/configs/global.config';
import { mapState } from 'vuex'
export default {
	name: 'Branches',

	data: () => ({
		deleteDialog: false,
		deleteItemId: null,
		
		restoreDialog: false,
		restoreItemId: null,

		// configs
		useOneBranch,

		// filter
		search: null,
		isFiltered: false,

		// helpers
		loading: false,
	}),

	computed: {
		...mapState({
			branches: state => state.branches.branches
		}),

		headers() {
			const headers = [
				{ text: this.$t('headers.image'), value: 'image', align: 'center', sortable: false },
				{ text: this.$t('headers.name'), value: 'name', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.country-code'), value: 'countryCode', align: 'center' },
				{ text: this.$t('headers.money-code'), value: 'moneyCode', align: 'center' },
				{ text: this.$t('headers.open-hours'), value: 'openHours', align: 'center' },
				{ text: this.$t('headers.main-branch'), value: 'isMain', align: 'center' },
				{ text: this.$t('headers.actions'), value: 'actions', align: 'center', sortable: false },
			];

			if (!useUploadBranchImage) {
				headers.splice(0, 1);
			}
			if (!branchInfo.countryCode) {
				headers.splice(headers.findIndex(c => c.value === 'countryCode'), 1);
			}
			if (!branchInfo.moneyCode) {
				headers.splice(headers.findIndex(c => c.value === 'moneyCode'), 1);
			}
			if (!branchInfo.openingHours) {
				headers.splice(headers.findIndex(c => c.value === 'openHours'), 1);
			}

			return headers
		},

		filteredBranches() {
			return this.filterByName(this.isFiltered, this.branches, this.search);
		}
	},

	methods: {
		fetchBranches() {
			this.loading = true;
			this.$store.dispatch('branches/fetchAll')
				.finally(() => {
					this.loading = false;
				})
		},
	},

	created() {
		this.fetchBranches();
	}
}
</script>

<style>

</style>