<template>
	<div>
		<!-- lang tabs -->
		<v-lang-tabs
			v-if="multiLangInputs"
			v-model="lang"
			:disable-non-selected="isInputsChanged || loading || submitLoading"
			background-color="white"
			class="mb-2"
			@change="updateFields"
		/>

		<!-- about -->
		<template v-if="showAbout">
			<p class="text-h6 font-weight-bold mb-4">{{$t('AboutUs.about')}}</p>
			<text-editor
				editorId="about"
				v-model="about"
				:loading="loading"
				@input="isInputsChanged = true"
			/>
		</template>

		<!-- mission -->
		<template v-if="showMission">
			<p class="text-h6 font-weight-bold mb-4 mt-6">{{$t('AboutUs.mission')}}</p>
			<text-editor
				editorId="mission"
				v-model="mission"
				:loading="loading"
				@input="isInputsChanged = true"
			/>
		</template>

		<!-- vision -->
		<template v-if="showVision">
			<p class="text-h6 font-weight-bold mb-4 mt-6">{{$t('AboutUs.vision')}}</p>
			<text-editor
				editorId="vision"
				v-model="vision"
				:loading="loading"
				@input="isInputsChanged = true"
			/>
		</template>

		<!-- policy -->
		<template v-if="showPolicy">
			<p class="text-h6 font-weight-bold mb-4 mt-6">{{$t('AboutUs.policy')}}</p>
			<text-editor
				editorId="policy"
				v-model="policy"
				:loading="loading"
				@input="isInputsChanged = true"
			/>
		</template>

		<!-- quality policy -->
		<template v-if="showQualityPolicy">
			<p class="text-h6 font-weight-bold mb-4 mt-6">{{$t('AboutUs.qualityPolicy')}}</p>
			<text-editor
				editorId="qualityPolicy"
				v-model="qualityPolicy"
				:loading="loading"
				@input="isInputsChanged = true"
			/>
		</template>

		<v-btn
			color="primary"
			class="rounded-medium mt-6"
			:loading="submitLoading"
			:disabled="loading || submitLoading"
			@click="submit"
		>
			{{$t('save')}}
		</v-btn>
	</div>
</template>

<script>
import { aboutUs } from '@/configs/global.config';
import { mapState } from 'vuex';
import { defaultLang, multiLangInputs } from '@/configs/translates.config';

export default {
	name: 'AboutUs',

	data: () => ({
		// configs
		multiLangInputs,
		showAbout: aboutUs.showAbout,
		showMission: aboutUs.showMission,
		showVision: aboutUs.showVision,
		showPolicy: aboutUs.showPolicy,
		showQualityPolicy: aboutUs.showQualityPolicy,
		
		// helpers
		loading: false,
		submitLoading: false,
		lang: defaultLang,
		isInputsChanged: false,
		
		// data
		about: null,
		mission: null,
		vision: null,
		policy: null,
		qualityPolicy: null,
	}),

	computed: {
		...mapState({
			aboutUs: state => state.aboutUs.aboutUs
		})
	},

	methods: {
		submit() {
			if (this.about || this.mission || this.vision || this.policy || this.qualityPolicy) {
				this.submitLoading = true;
				this.$store.dispatch('aboutUs/create', {
					about: this.showAbout ? this.about : null,
					mission: this.showMission ? this.mission : null,
					vision: this.showVision ? this.vision : null,
					policy: this.showPolicy ? this.policy : null,
					qualityPolicy: this.showQualityPolicy ? this.qualityPolicy : null,
					acceptLanguage: this.lang
				})
					.then(() => {
						this.isInputsChanged = false;
						
						const message = this.$t('$-is-updated-successfully', { name: this.$t('AboutUs.about') })
						this.$eventBus.$emit('show-snackbar', false, message);
						this.fetchAboutUs();
					})
					.finally(() => {
						this.submitLoading = false;
					})
			}
		},

		fillToEdit() {
			if (this.showAbout) this.about = this.aboutUs.find(c => c.key === 'about')?.value;
			if (this.showMission) this.mission = this.aboutUs.find(c => c.key === 'mission')?.value;
			if (this.showVision) this.vision = this.aboutUs.find(c => c.key === 'vision')?.value;
			if (this.showPolicy) this.policy = this.aboutUs.find(c => c.key === 'policy')?.value;
			if (this.showQualityPolicy) this.qualityPolicy = this.aboutUs.find(c => c.key === 'qualityPolicy')?.value;
		},

		updateFields() {
			this.fetchAboutUs();
		},

		fetchAboutUs() {
			this.loading = true;
			this.$store.dispatch('aboutUs/fetchAll', { acceptLanguage: this.lang })
				.then(() => {
					this.fillToEdit();
				})
				.finally(() => {
					this.loading = false;
				})
		}
	},

	created() {
		if (this.aboutUs.length === 0) {
			this.fetchAboutUs();
		} else {
			this.fillToEdit();
		}
	}
}
</script>

<style>

</style>