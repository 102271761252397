<template>
	<v-form ref="form" @submit.prevent="submit" class="px-2">
		<!-- lang tabs -->
		<v-lang-tabs
			v-if="multiLangInputs"
			v-model="lang"
			:disable-non-selected="isInputsChanged || submitLoading"
			class="mb-2"
			@change="updateFields"
		/>

		<!-- inputs -->
		<v-row class="mt-0 mb-5">
			<!-- input data -->
			<v-col
				:lg="useUploadCompanyHistoriesImage ? 9 : 12"
				:md="useUploadCompanyHistoriesImage ? 8 : 12"
				cols="12"
				order-md="0"
				order="1"
				class="pb-md-0"
			>
				<v-row class="mt-0 mb-2">
					<!-- title -->
					<v-col lg="6" cols="12">
						<v-text-field
							v-model="title"
							:label="$t('inputs.title')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="rules.required"
							@change="isInputsChanged = true"
						></v-text-field>
					</v-col>
				</v-row>
				
				<!-- description -->
				<div class="text-body-1 font-weight-bold text-capitalize mb-3">{{$t('inputs.description')}}</div>
				<text-editor v-model="description" @input="isInputsChanged = true" :loading="loading"/>
			</v-col>

			<v-col cols="auto" class="d-md-block d-none px-1">
				<v-divider vertical/>
			</v-col>

			<!-- drop image -->
			<v-col v-if="useUploadCompanyHistoriesImage" md cols="12" class="pb-md-0">
				<div class="sticky">
					<div class="text-body-1 font-weight-bold mb-3">
						{{$t('upload-images')}}
					</div>
					<bee-drop-img
						v-model="mainImage"
						dashed
						border-width="2"
						:border-color="isSubmitted && !mainImage ? '#f00' : '#ccc'"
						radius="11"
						height="250px"
						blurable
						clearable
						:loading="loading"
						:disabled="loading || submitLoading"
						:hide-image-name="false"
						disable-file-picker
						@input="isInputsChanged = true"
						@remove-image="fileManagerFiles.mainImage = {}"
						@click="$eventBus.$emit('file-manager', { images: true }, (url, file) => {
							mainImage = url;
							fileManagerFiles.mainImage = file;
							isInputsChanged = true;
						})"
					>
						<template v-slot:drag-text>
							<span class="text-body-1 font-weight-medium">
								{{$t('drag-text')}}
							</span>
							<span class="text-caption mb-0">
								{{$t('supported-text')}}
							</span>
						</template>
					</bee-drop-img>
					
					<bee-drop-zone
						v-if="uploadMoreImages"
						v-model="images"
						dashed
						drop-zone-classes="pa-3"
						radius="11"
						border-width="2"
						max-height="250"
						:drop-image-props="{
							width: 70,
							height: 70
						}"
						:clear-icon="$route.params.id ? 'mdi-delete' : 'mdi-close'"
						:clear-color="$route.params.id ? 'red' : null"
						:gap-size="4"
						:drop-menu-width="$vuetify.breakpoint.mdAndUp ? 400 : '100%'"
						:drop-menu-max-width="$vuetify.breakpoint.mdAndUp ? 400 : '100%'"
						:drop-menu-left="null"
						show-add-btn
						show-drop-menu
						no-drop-replace
						class="mt-3"
						:loading="loading"
						:disabled="loading || submitLoading"
						disable-file-picker
						@remove-image="removeImage"
						@input="isInputsChanged = true"
						@click="$eventBus.$emit('file-manager', { images: true, multiple: true }, (urls, files) => {
							images.push(...urls);
							fileManagerFiles.moreImages.push(...files);
							isInputsChanged = true;
						})"
					>
						<template v-slot:drag-text>
							<p class="mb-0">
								<span class="d-block text-body-1 font-weight-bold">
									{{$t('upload-more-image')}}
								</span>
								<span class="d-block text-caption mb-0">{{$t('drag-text')}}</span>
							</p>
						</template>
					</bee-drop-zone>
				</div>
			</v-col>
		</v-row>
		<v-btn
			type="submit"
			color="primary"
			class="rounded-medium me-2"
			:loading="submitLoading"
			:disabled="loading || submitLoading"
		>
			{{$t('save')}}
		</v-btn>
		<v-btn
			color="white"
			class="rounded-medium"
			:to="{ name: backRouteName }"
			:disabled="loading || submitLoading"
		>
			{{$t('back')}}
		</v-btn>
	</v-form>
</template>

<script>
import rules from '@/helpers/validation rules'
import { companyHistories } from '@/configs/routes.config';
import { defaultLang, multiLangInputs } from '@/configs/translates.config';
import { useUploadCompanyHistoriesImage, useUploadMoreImages } from '@/configs/global.config';
export default {
	name: 'CompanyValue',
	
	data: () => ({
		// helpers
		loading: false,
		submitLoading: false,
		isSubmitted: false,
		productImages: [],
		lang: defaultLang,
		isInputsChanged: false,
		backRouteName: companyHistories.name,
		rules,

		// configs
		multiLangInputs,
		useUploadCompanyHistoriesImage,
		uploadMoreImages: useUploadMoreImages.companyHistories,

		// file manager
		fileManagerFiles: {
			mainImage: {},
			moreImages: [],
		},
		
		// data
		title: null,
		description: null,
		mainImage: null,
		images: [],
	}),

	watch: {
		$route(newVal) {
			if (newVal.params.id) {
				this.loading = true;
				this.fetchCompanyValues().finally(() => {
					this.isInputsChanged = false;
					this.loading = false;
				});
			}
		}
	},

	methods: {
		submit() {
			this.isSubmitted = true;
			const image = useUploadCompanyHistoriesImage ? this.mainImage : true;
			if (this.$refs.form.validate() && image) {
				this.submitLoading = true;
				const actionName = this.$route.params.id ? 'update' : 'create';
				// more images
				const images = this.images.filter(c => typeof c !== 'string');

				this.$store.dispatch(`companyHistories/${actionName}`, {
					id: this.$route.params.id,
					title: this.title,
					content: this.description,
					image: typeof this.mainImage !== 'string' ? this.mainImage : null,
					imagePath: typeof this.mainImage === 'string' ? this.fileManagerFiles.mainImage.publicPath : null,
					imageName: typeof this.mainImage === 'string' ? this.fileManagerFiles.mainImage.fileName : null,
					acceptLanguage: this.lang
				}).then((data) => {
					// next route
					const nextRoute = () => {
						const message =
							actionName === 'update'
								? this.$t('$-is-updated-successfully', { name: this.$t('CompanyHistories.company-history') })
								: this.$t('$-is-created-successfully', { name: this.$t('CompanyHistories.company-history') });
						this.$eventBus.$emit('show-snackbar', false, message);

						if (multiLangInputs) {
							this.isInputsChanged = false;
							if (!this.$route.params.id) {
								this.$router.replace({
									name: this.$route.name,
									params: { slug: data.slug, id: data.id }
								});
							}
						} else {
							this.$router.push({ name: companyHistories.name });
						}
					}

					// upload the other images
					if (images.length || this.fileManagerFiles.moreImages.length) {
						this.$eventBus.$emit('show-snackbar', false, this.$t('please-wait-uploading-the-images'))
						this.$store.dispatch('companyHistories/uploadImages', {
							id: data.id,
							images,
							imagesPaths: this.fileManagerFiles.moreImages.map(c => c.publicPath),
							imagesNames: this.fileManagerFiles.moreImages.map(c => c.fileName),
						}).then(() => {
							nextRoute();
						}).finally(() => {
							this.submitLoading = false;
						})
					} else {
						nextRoute();
					}
				}).finally(() => {
					if (!images.length) {
						this.submitLoading = false;
					}
				})
			}
		},

		// FIXME show dialog to confirm remove
		removeImage(image) {
			if (this.$route.params.id) {
				const imageId = this.productImages.find(c => c.url === image)?.id;
				
				if (imageId) {
					this.$store.dispatch('companyHistories/deleteImage', {
						id: this.$route.params.id,
						imageId: imageId
					})
				}
			}

			const fileManagerImageId = this.fileManagerFiles.moreImages.find(c => c.url === image)?.id;
			if (fileManagerImageId) {
				const imageIndex = this.fileManagerFiles.moreImages.findIndex(c => c.id === fileManagerImageId);
				this.fileManagerFiles.moreImages.splice(imageIndex, 1);
			}
		},

		updateFields() {
			if (this.$route.params.id) {
				this.$refs.form.reset();
				this.loading = true;
				this.fetchCompanyValues().finally(() => {
					this.isInputsChanged = false;
					this.loading = false
				});
			}
		},

		fetchCompanyValues() {
			return this.$store.dispatch('companyHistories/fetchBySlug', {
				slug: this.$route.params.slug,
				acceptLanguage: this.lang
			}).then((data) => {
				this.title = data.title;
				this.description = data.content;
				this.mainImage = data.primaryImage.url;
				this.images = data.images.map(c => c.url).filter(c => c !== data.primaryImage.url);

				this.productImages = data.images.filter(c => c !== data.primaryImage.url);
			})
		}
	},

	created() {
		if (this.$route.params.id) {
			this.loading = true;
			this.fetchCompanyValues().finally(() => {
				this.loading = false;
			})
		}
	}
};
</script>

<style lang="scss">
</style>